jQuery(document).ready(function ($) {
    
    
    // search users

    // $('.search-click').click(function () {
    //     $(this).parents('.search-users').toggleClass('sb-search-open');
    // });


    // add users

    $('.add-user-button').click(function () {
        $('body').addClass('hide-scrollbar');
        $('.custom-overlay').show();
        $('.large-sidenav-modal').addClass('animate-me');
    });

    $(document).on('click', ".close-side-nav,.custom-overlay", function () {
        $('.custom-overlay').hide();
        $('body').removeClass('hide-scrollbar');
        $('.large-sidenav-modal').removeClass('animate-me');
        $('.medium-sidenav-modal').removeClass('animate-me');
        $('.small-sidenav-modal').removeClass('animate-me');
    });
        
    $('.next-permission-block').click(function () {
        $(this).parents('.custom-sidenav').find('.new-user-form').hide();
        $(this).parents('.custom-sidenav').find('.Permission-block').show();
    });

    $('.back-new-user').click(function () {
        $(this).parents('.custom-sidenav').find('.Permission-block').hide();
        $(this).parents('.custom-sidenav').find('.new-user-form').show();
    });

    $('.reset-permission').click(function () {
        $('.permission-catalog').find('.checkbox-custom').removeAttr('checked');
    });

    // Edit user info
    $('.updated-user-info').click(function () {
        $('body').addClass('hide-scrollbar');
        $('.custom-overlay').show();
        $('.large-sidenav-modal').addClass('animate-me');
    });

    // Create Project
    $('.create-project-btn,.open-create-project').click(function () {
        $('body').addClass('hide-scrollbar');
        $('.custom-overlay').show();
        $('.small-sidenav-modal').addClass('animate-me');
    });
    // Edit Project
    $('.edit-project-button').click(function () {
        $('body').addClass('hide-scrollbar');
        $('.custom-overlay').show();
        $('.small-sidenav-modal').addClass('animate-me');
    });

    // Change Password
    $('.change-password-btn').click(function () {
        $('body').addClass('hide-scrollbar');
        $('.custom-overlay').show();
        $('.small-sidenav-modal').addClass('animate-me');
    });

    
    // 14a. user Technician
    $('.technician-list-link').click(function () {
        $('body').addClass('hide-scrollbar');
        $('.custom-overlay').show();
        $('.medium-sidenav-modal').addClass('animate-me');
    });

    // payment Details
    $('.payment-btn').click(function () {
        $('body').addClass('hide-scrollbar');
        $('.custom-overlay').show();
        $('.medium-sidenav-modal').addClass('animate-me');
    });

    // Raing with review sideNav 
    $('.rating-with-review-side-btn').click(function () {
        $('body').addClass('hide-scrollbar');
        $('.custom-overlay').show();
        $('.large-sidenav-modal').addClass('animate-me');
    });
      
    $('.custom-overlay,.leave-model').click(function () {
        $('.custom-overlay').hide();
        $('body').removeClass('hide-scrollbar');
        $('.save-template-modal').hide();
        $('.profile-badge-modal').hide();
    });

    // for profile web
    $('.my-name').click(function () {
        $(".my-name").toggleClass("active");
        $(this).parents('.user-name-info').find('.user-profile-dropdown').toggle();
    });
    

    $(document).click(function (event) {
        $('.user-profile-dropdown').hide();
        $('.profile-overlay-mob').hide();
        $(".my-name").removeClass("active");
       
        //$('.filter-post').removeClass("active");
        //$('.search-users').removeClass('sb-search-open');
    })

    
    
    $('.my-name,.user-profile-dropdown').click(function (e) {
        e.stopPropagation();
    });

    // for user profile dropdown mobile

    if ($(window).width() < 768) {
        $('.profile-img').click(function () {
            $('.profile-overlay-mob').toggle();
            $(this).parents('.user-type').find('.user-profile-dropdown').toggle();
        });

        $('.profile-img,.user-profile-dropdown,.profile-overlay-mob').click(function (e) {
            e.stopPropagation();
        });

        $('.profile-overlay-mob').click(function () {
            $(this).hide();
            $('.user-profile-dropdown').hide();
        })
        $('.search.btn-anis-effect').click(function () {
            $('.search-container-overlay').show();
            $('.search-container.for-mobile').hide();
        });
    }
    //create work order menu in mobile
    $(document).on('click', ".slide-btn-mob", function () {
        $('html').addClass('hide-scrollbar');
        $(this).parents('.work-order-step').find('.order-step-list').addClass('active-slide');
        $('.overlay-step-menu').show();
    });
    $(document).on('click', ".slide-btn-arrow-mob", function () {
        $('html').removeClass('hide-scrollbar');
        $(this).parents('.order-step-list').removeClass('active-slide');
        $(this).hide();
        $('.overlay-step-menu').hide();
    });
    

    $(document).on('click', ".overlay-step-menu", function () {
        $(this).hide();
        $('.order-step-list').removeClass('active-slide');
        $('html').removeClass('hide-scrollbar');
    });

    $(document).on('click', ".order-info-list", function () {
        $('.overlay-step-menu').hide();
        
        $(this).parents('.order-step-list').removeClass('active-slide');
        $('html').removeClass('hide-scrollbar');
    })

    // Header search
    $('.filter-by-btn').click(function () {
        $(this).toggleClass('active');
        $('.search-container-overlay').show();
        $('.search-container.for-mobile').toggle();
    })
    $('.search-container-overlay').click(function () {
        $(this).hide();
        $('.filter-by-btn').removeClass('active');
        $('.search-container.for-mobile').hide();
    });

    

    // Status OS for mobile
    $('.status-chain-phone').click(function () {
        $(this).toggleClass('active');
        $('.os-installation-steps').toggle();
    });
    // select checked only one

    $('.dropdown-menu').on('click', function(event){
        // The event won't be propagated up to the document NODE and 
        // therefore delegated events won't be fired
        event.stopPropagation();
    });
    $('.checked-one [type="checkbox"]').change(function(){
        if(this.checked){
           $('[type="checkbox"]').not(this).prop('checked', false);
        }    
    });

    if ($(window).width() < 1024) {
        $('.search.btn-anis-effect').click(function () {
            $('.search-container-overlay').show();
            $('.search-container.for-mobile').hide();
        });
    }
       

});

$(function() {
    $('.rd-navbar-nav-wrap').removeClass('active')
});

